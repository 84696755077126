var swiper = new Swiper(".swiper", {
  pagination: {
    el: ".swiper-pagination",
    type: "progressbar",
  },

  grabCursor: true,
  spaceBetween: 30,
  loop: true,

  autoplay: {
    delay: 2000,
    disableOnInteraction: false,
  },

  speed: 700,
});

var swiperContainer = document.querySelector(".swiper");

swiperContainer.addEventListener("mouseenter", function () {
  swiper.autoplay.stop(); // Остановка автопрокрутки
});

swiperContainer.addEventListener("mouseleave", function () {
  swiper.autoplay.start();
});
