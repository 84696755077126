var languages = document.querySelectorAll(".header__descr-lang_item");

languages.forEach(function (language) {
  language.addEventListener("click", function (event) {
    event.preventDefault();

    languages.forEach(function (lang) {
      lang.classList.remove("active");
    });

    this.classList.add("active");
  });
});
