const searchBox = document.querySelector(".search-box");
const searchBtn = document.querySelector(".search-icon");
const cancelBtn = document.querySelector(".cancel-icon");
const searchInput = document.querySelector("input");
const searchData = document.querySelector(".search-data");
const hiddenInput = document.querySelector(".icon-input");

function handleSearchBtnClick() {
  if (window.innerWidth > 768) {
    // Выполняем ваш исходный скрипт на экранах с шириной > 768px
    searchBox.classList.add("active");
    searchBtn.classList.add("active");
    searchInput.classList.add("active");
    cancelBtn.classList.add("active");
    searchInput.focus();
    if (searchInput.value != "") {
      var values = searchInput.value;
      searchData.classList.remove("active");
    } else {
      searchData.textContent = "";
    }
    // Скрываем скрытый input и иконку поиска
    hiddenInput.style.display = "none";
    searchBtn.style.right = "20px";
    searchBtn.style.width = "30px";
  } else {
    // Заменяем иконку поиска на иконку закрытия на экранах с шириной <= 768px
    searchBtn.style.display = "none";
    cancelBtn.style.right = "20px";
    cancelBtn.style.width = "30px";
    cancelBtn.style.display = "block";
    hiddenInput.style.display = "block";
    hiddenInput.focus();
  }
}

function handleCancelBtnClick() {
  if (window.innerWidth > 768) {
    searchBox.classList.remove("active");
    searchBtn.classList.remove("active");
    searchInput.classList.remove("active");
    cancelBtn.classList.remove("active");
    searchData.classList.toggle("active");
    searchInput.value = "";
    searchBtn.style.right = ""; // Сбрасываем стили right и width для searchBtn
    searchBtn.style.width = "";
  } else {
    // Заменяем иконку закрытия на иконку поиска на экранах с шириной <= 768px
    searchBtn.style.right = "20px";
    searchBtn.style.width = "30px";
    searchBtn.style.display = "block";
    cancelBtn.style.display = "none";
    hiddenInput.style.display = "none";
  }
}

searchBtn.onclick = handleSearchBtnClick;
cancelBtn.onclick = handleCancelBtnClick;

const searchInputMobile = document.querySelector(".test");
const searchIcon = document.querySelector(".search-icon_mobile");

function performSearch() {
  const searchQuery = searchInput.value;
  // Вместо этого места можно добавить код для выполнения поиска с заданным запросом
  console.log("Выполняем поиск для запроса: " + searchQuery);
}

// Добавляем обработчик клика на иконку поиска
searchIcon.onclick = performSearch;
