var sidebar = document.querySelector(".sidebar");
var scrollbox = sidebar.querySelector(".scrollbox");
var scrollContent = sidebar.querySelector(".scrollbox__inner");

scrollbox.addEventListener("scroll", function () {
  var scrollTop = scrollbox.scrollTop;
  var scrollHeight = scrollContent.offsetHeight;
  var clientHeight = scrollbox.clientHeight;

  if (scrollTop + clientHeight >= scrollHeight) {
    sidebar.classList.add("scrolled");
  } else {
    sidebar.classList.remove("scrolled");
  }
});
