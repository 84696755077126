document.addEventListener("DOMContentLoaded", function () {
  const burger = document.querySelector(".header__burger");
  const nav = document.querySelector(".nav");

  burger.addEventListener("click", function (event) {
    burger.classList.toggle("activity");
    nav.classList.toggle("activity");
    document.body.classList.toggle("lock");
    document.documentElement.classList.toggle("lock");
  });
});
