//изм языка
import "./components/language-change";

//строка поиска
import "./components/search";

//бургер-меню
import "./components/burger";

//сайдбар
import "./components/sidebar";

//слайдер
import "./components/slider";

//логика кнопки "загрузить еще"
import "./components/loadButton";
