var loadMoreButton = document.querySelector(".load-more-button");
var blocks = document.querySelectorAll(".article__block");
var currentIndex = 1; // Начинаем с первого блока (поскольку первый блок уже виден)

// Функция для показа следующего блока
function showNextBlock() {
  if (currentIndex < blocks.length) {
    blocks[currentIndex].style.display = "block";
    currentIndex++;
  }

  // Если все блоки уже показаны, скрыть кнопку "Load More"
  if (currentIndex >= blocks.length) {
    loadMoreButton.style.display = "none";
  }
}

// Обработчик клика на кнопку "Load More"
loadMoreButton.addEventListener("click", function () {
  showNextBlock();
});
